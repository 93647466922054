import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Footer from '@sections/Footer';

import Header from '@sections/Header';
import TestimonialCta from '@sections/TestimonialCta';
import Listings from '@sections/Listings';
import Reviews from '@sections/Reviews';
import Services from '@sections/Services';
import About from '@sections/About';
import Contact from '@sections/Contact';

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      rentec {
        properties {
          address
          bathrooms
          bedrooms
          city
          images {
            imagelink
          }
          nickname
          property_id
          rent
          sqft
          state
          zip
        }
      }

      allSanityProperties {
        edges {
          node {
            id
            address
            bathrooms
            bedrooms
            city
            id
            images {
              image {
                _key
                caption
                alt
                asset {
                  url
                  fluid(maxWidth: 600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            nickname
            price
            state
            underContract
            zip
            sqft
          }
        }
      }

      sanityHomepage {
        _id
        title
        feature {
          _key
          button1 {
            isGhost
            url
            text
          }
          button2 {
            isGhost
            text
            url
          }
          heading
          rotatingText
          timeOnSlide
          image {
            asset {
              url
              fluid(maxWidth: 1200) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        _rawFeature
        _rawTestimonial
        testimonial {
          person
          button {
            isGhost
            text
            url
          }
        }
        listings {
          sectionHeading {
            location {
              lat
              lng
            }
            heading
          }
        }
        _rawReviews
        reviews {
          image {
            asset {
              fluid(maxWidth: 1200) {
                ...GatsbySanityImageFluid
              }
            }
          }
          button {
            isGhost
            text
            url
          }
          review {
            person
            _key
            rating
          }
          sectionHeading {
            heading
            subHeading
            subHeadingColor
            location {
              lng
              lat
            }
          }
        }
        _rawServices
        services {
          images {
            _key
            alt
            caption
            asset {
              fluid(maxWidth: 1200) {
                ...GatsbySanityImageFluid
              }
            }
          }
          sectionHeading {
            heading
            subHeading
            subHeadingColor
            location {
              lng
              lat
            }
          }
        }
        _rawAbout
        about {
          image {
            _key
            alt
            caption
            asset {
              fluid(maxWidth: 1200) {
                ...GatsbySanityImageFluid
              }
            }
          }
          sectionHeading {
            heading
            subHeading
            subHeadingColor
            location {
              lng
              lat
            }
          }
          quotes {
            _key
            person
            personPosition
            quote
            signature {
              alt
              asset {
                fluid(maxWidth: 1200) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        _rawContact
        contact {
          location {
            lat
            lng
          }
          sectionHeading {
            heading
            subHeading
            subHeadingColor
            location {
              lng
              lat
            }
          }
        }
      }
    }
  `);

  const SEO_DATA = {
    description:
      'Home buying, selling, and renting in Charleston South Carolina made Easy',
    title: 'Shahid Properties',
    url: location.href,
    author: 'Shahid Properties',
    keywords: [
      'Charleston',
      'Real Estate',
      'House Buying',
      'House Selling',
      'House Renting',
    ],
    twitter: {
      id: '',
      img: data.sanityHomepage.feature.image.asset.url,
    },
    facebook: {
      id: '',
      img: data.sanityHomepage.feature.image.asset.url,
    },
  };

  const isHome = location.pathname === '/';

  return (
    <Layout seo={SEO_DATA}>
      <Navbar isHome={isHome} data={data.sanityHomepage} />
      <Header data={data.sanityHomepage} />
      <TestimonialCta data={data.sanityHomepage} />
      <Listings data={data} />
      <Reviews
        data={data.sanityHomepage.reviews}
        rawData={data.sanityHomepage._rawReviews}
      />
      <Services
        data={data.sanityHomepage.services}
        rawData={data.sanityHomepage._rawServices}
      />
      <About
        data={data.sanityHomepage.about}
        rawData={data.sanityHomepage._rawAbout}
      />
      <Contact
        data={data.sanityHomepage.contact}
        rawData={data.sanityHomepage._rawContact}
      />
      <Footer data={data.sanityHomepage} />
    </Layout>
  );
};

export default IndexPage;
