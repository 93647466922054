import React from 'react';
import styled from 'styled-components';
import { Section, Container } from '@components/global';
import GoogleMapReact from 'google-map-react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { theme } from '../styles/theme';
import { createMapOptions } from '../helpers/mapStyles';

const SectionHeaderWrap = styled(Section)`
  padding: 50px 0 !important;
  background-color: ${props => props.theme.color.primary};
  position: relative;
  overflow: hidden;
  margin: 0;

  @media (min-width: ${props => props.theme.screen.md}) {
    padding: 100px 0 !important;
  }
`;

const WrapGoogleMap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

const GoogleMap = styled(GoogleMapReact)`
  height: 100%;
  width: 100%;
  z-index: 0;
`;

const TextAreaWrap = styled.div`
  z-index: 1;
  position: relative;
  /* float: left; */
  width: 80%;
  margin: 0 auto;
  text-align: center;
  max-width: 575px;

  @media (min-width: ${props => props.theme.screen.md}) {
    float: left;
    margin: 0 0 0 0;
    margin-left: calc(((100vw - 1020px) / 2) + 30px);
    text-align: left;
    width: auto;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    margin-left: calc(((100vw - 1260px) / 2) + 30px);
  }
`;

const TextArea = styled.div`
  background-color: ${props => props.theme.color.white};
  box-shadow: 0px 15px 20px rgba(59, 57, 52, 0.1);
  padding: 20px;

  @media (min-width: ${props => props.theme.screen.md}) {
    padding: 50px;
  }

  h2 {
    font-family: ${props => props.theme.font.primary};
    font-weight: ${props => props.theme.font_weight.bold};
    ${props => props.theme.font_size.large};

    @media (min-width: ${props => props.theme.screen.sm}) {
      font-size: 35px;
    }
    @media (min-width: ${props => props.theme.screen.md}) {
      ${props => props.theme.font_size.xlarge};
    }
  }

  .primary {
    color: ${props => props.theme.color.primary};
  }

  .secondary {
    color: ${props => props.theme.color.secondary};
  }

  span,
  h1 {
    font-family: ${props => props.theme.font.secondary};
    color: ${props => props.theme.color.font.regular};
    text-transform: uppercase;
    font-weight: ${props => props.theme.font_weight.medium};
    ${props => props.theme.font_size.regular};
    letter-spacing: ${props => props.theme.letter_spacing.loose};
    margin-bottom: 0;
    display: block;

    @media (min-width: ${props => props.theme.screen.sm}) {
      margin-bottom: 15px;
    }

    a {
      text-decoration: underline;
      color: ${props => props.theme.color.primary};
    }
  }
`;

class SectionHeader extends React.Component {
  static defaultProps = {
    center: {
      lat: 32.775596,
      lng: -79.940535,
    },
    zoom: 15,
  };

  render() {
    return (
      <SectionHeaderWrap>
        <LazyLoadComponent>
          <WrapGoogleMap>
            <GoogleMap
              bootstrapURLKeys={{
                key:
                  process.env.GOOGLE_MAP_KEY ||
                  'AIzaSyBgG1i8HUFcO15oY1eOhhhoERnVt5O8d5A',
              }}
              defaultCenter={this.props.center}
              defaultZoom={this.props.zoom}
              options={createMapOptions}
            >
              {/* <AnyReactComponent lat={59.955413} lng={30.337844} text="My Marker" /> */}
            </GoogleMap>
          </WrapGoogleMap>
        </LazyLoadComponent>
        <TextAreaWrap>
          <TextArea>{this.props.children}</TextArea>
        </TextAreaWrap>
      </SectionHeaderWrap>
    );
  }
}

export default SectionHeader;
