import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import GoogleMapReact from 'google-map-react';

import { ReactComponent as Star } from '@static/icons/star.svg';
import starUrl, { ReactComponent as Marker } from '@static/images/marker.svg';
import { Section, Container, Columns, ColumnsGrid } from '@components/global';
import BlockContent from '../common/BlockContent';
import {
  createMapOptions,
  createMapOptionsSmall,
} from '../../helpers/mapStyles';
import ExternalLink from '../common/ExternalLink';
import { Button } from '../common/Button';

import SectionHeader from '../SectionHeader';

const Contact = ({ data, rawData }) => {
  const mapCenter = {
    lat: data.sectionHeading.location.lat,
    lng: data.sectionHeading.location.lng,
  };

  const MarkerPin = {
    lat: data.location.lat,
    lng: data.location.lng,
  };

  const OfficeCenter = {
    lat: data.location.lat - 0.001,
    lng: data.location.lng,
  };

  return (
    <section id="contact">
      <SectionHeader center={mapCenter} zoom={15}>
        <h1 className={data.sectionHeading.subHeadingColor}>
          {data.sectionHeading.subHeading}
        </h1>
        <h2>{data.sectionHeading.heading}</h2>
      </SectionHeader>
      <Section>
        <Container>
          <ColumnsGrid className="content" alignItems="center">
            {rawData.text && (
              <div>
                <BlockContent data={rawData.text} />
              </div>
            )}
            <div>
              <GoogleMapWrap>
                <div>
                  <GoogleMap
                    bootstrapURLKeys={{
                      key: 'AIzaSyBgG1i8HUFcO15oY1eOhhhoERnVt5O8d5A',
                    }}
                    defaultCenter={OfficeCenter}
                    defaultZoom={15}
                    options={createMapOptionsSmall}
                  >
                    <MarkerNew
                      lat={MarkerPin.lat}
                      lng={MarkerPin.lng}
                      text="Shahid Properties"
                    />
                  </GoogleMap>
                </div>
              </GoogleMapWrap>
            </div>
          </ColumnsGrid>
        </Container>
      </Section>
    </section>
  );
};

const GoogleMapWrap = styled.div`
  height: 0;
  padding-top: calc((440 / 575) * 100%);
  position: relative;

  > div {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const GoogleMap = styled(GoogleMapReact)`
  height: 100%;
  width: 100%;
  z-index: 0;
`;

const MarkerNew = props => <Marker />;

export default Contact;
