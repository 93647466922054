import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Typical from 'react-typical';

import { Container as HeaderContainer } from '@components/global';
import ExternalLink from '@common/ExternalLink';
import { Button as HeaderButton } from '../common/Button';
import BlockContent from '../common/BlockContent';

const Header = ({ data }) => {
  const typingDelay = 3000;

  return (
    <section>
      <HeaderWrapper id="home">
        <Container>
          <Grid>
            <Text>
              <h1>
                <Typical
                  steps={[
                    'Buying',
                    typingDelay,
                    'Selling',
                    typingDelay,
                    'Renting',
                    typingDelay,
                  ]}
                  loop={Infinity}
                  wrapper="span"
                />
                <br />
                In Charleston Made Easy
              </h1>
              {/* <p>
                  <strong>Buying</strong>, <strong>Selling</strong> and{' '}
                  <strong>Renting</strong> a home can be tough… but not with
                  Shahid Properties!
                </p> */}
              {data._rawFeature.subHeading && (
                <BlockContent data={data._rawFeature.subHeading} />
              )}
              <ButtonsWrap>
                <Button
                  ghost={data.feature.button1.isGhost ? 'true' : 'false'}
                  href={data.feature.button1.url}
                  offset={() => 109}
                >
                  {data.feature.button1.text}
                </Button>
                <Button
                  ghost={data.feature.button2.isGhost ? 'true' : 'false'}
                  href={data.feature.button2.url}
                  offset={() => 109}
                >
                  {data.feature.button2.text}
                </Button>
              </ButtonsWrap>
            </Text>
            {data.feature &&
              data.feature.image &&
              data.feature.image.asset &&
              data.feature.image.asset.fluid && (
                <Art
                  fluid={{
                    ...data.feature.image.asset.fluid,
                    aspectRatio: 744 / 687,
                  }}
                  alt="Shahid Properties"
                />
              )}
            {/* todo */}
          </Grid>
        </Container>
      </HeaderWrapper>
    </section>
  );
};

const Container = styled(HeaderContainer)`
  height: 100%;
  max-width: 100% !important;
  padding: 0;
`;

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.primary};
  background-image: url('/images/hero-map.jpg'); //todo
  background-attachment: fixed;
  padding: 20px;
  padding-top: 60px;
  height: auto;
  border: 20px ${props => props.theme.color.white} solid;
  text-align: center;

  @media (min-width: ${props => props.theme.screen.md}) {
    /* min-height: 80vh; */
    /* height: 80vh; */
    padding: 52px 0px 0 80px;
    text-align: left;
  }

  @media (min-width: ${props => props.theme.screen.xl}) {
    padding: 60px 30px 0 80px;
  }

  @media (min-width: ${props =>
      props.theme.screen.xl}) and (min-height: 830px) {
    max-height: 80vh;
  }
`;

const Art = styled(Img)`
  width: 100%;
  /* height: calc(100% + 100px); */
  /* margin: 80px 0 0; */
  /* object-fit: cover; */
  border: 10px ${props => props.theme.color.white} solid;
  align-self: start;

  @media (min-width: ${props => props.theme.screen.md}) {
    border-right: none;
    border-bottom: none;
    margin-top: 50px;
    height: calc(90% + 100px);
    border-width: 20px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    /* margin-top: 150px; */
    height: calc(90% + 100px);
  }
  @media (min-width: ${props => props.theme.screen.xl}) {
    /* margin-top: 150px; */
    height: calc(80% + 100px);
  }
`;

const Grid = styled.div`
  height: 100%;
  display: grid;
  /* grid-template-columns: 4fr minmax(auto, 850px); */
  grid-template-columns: 5fr 5fr;
  align-items: center;
  grid-gap: 50px;

  max-width: 1700px;
  margin: 0 auto;

  @media (max-width: ${props => props.theme.screen.xl}) {
    grid-template-columns: 4fr 6fr;
  }
  @media (max-width: ${props => props.theme.screen.lg}) {
    grid-template-columns: 4fr 6fr;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;
    /* padding-bottom: 50px; */
    grid-gap: 30px;

    > ${Art} {
      order: 2;
    }
  }
`;

const Text = styled.div`
  justify-self: left;
  transform: translateY(-40px);

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
    transform: translateY(0);
    margin-top: 30px;
  }
  @media (max-width: ${props => props.theme.screen.lg}) {
    margin-top: 50px;
  }

  h1 {
    font-size: 40px;
    font-weight: ${props => props.theme.font_weight.bold};
    line-height: 116.1%;

    @media (min-width: 525px) {
      font-size: 60px;
    }

    @media (min-width: ${props => props.theme.screen.md}) {
      font-size: 48px;
    }

    @media (min-width: ${props => props.theme.screen.lg}) {
      font-size: 60px;
    }

    @media (min-width: 1366px) {
      font-size: 69px;
    }
  }

  p {
    margin: 30px 0;
    color: ${props => props.theme.color.black};
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.color.black};
  }
`;

const Button = styled(HeaderButton)``;

const ButtonsWrap = styled.div`
  ${Button} {
    @media (max-width: ${props => props.theme.screen.xl}) {
      width: 100%;
      margin-left: 0;
      margin-bottom: 15px;
    }

    & + & {
    }
  }
`;

export default Header;
