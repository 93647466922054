import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { ReactComponent as Star } from '@static/icons/star.svg';

import { ReactComponent as ZillowIcon } from '@static/icons/header/zillow.svg';
import { Section, Container, Columns, ColumnsGrid } from '@components/global';
import { Button } from '../common/Button';
import BlockContent from '../common/BlockContent';
import SectionHeader from '../SectionHeader';

const Reviews = ({ data, rawData }) => {
  const mapCenter = {
    lat: data.sectionHeading.location.lat,
    lng: data.sectionHeading.location.lng,
  };

  return (
    <section id="services">
      <SectionHeader center={mapCenter} zoom={15}>
        <h1 className={data.sectionHeading.subHeadingColor}>
          {data.sectionHeading.subHeading}
        </h1>
        <h2>{data.sectionHeading.heading}</h2>
      </SectionHeader>
      <Section>
        <Container>
          {rawData.mainText && (
            <CenterText>
              <BlockContent data={rawData.mainText} />
            </CenterText>
          )}
          <ColumnsGrid className="content" alignItems="top">
            {rawData.sideText && (
              <div>
                <BlockContent data={rawData.sideText} />
              </div>
            )}

            {data.images && data.images.length > 0 && (
              <div>
                {data.images.map(image => (
                  <figure key={image._key}>
                    {image && image.asset && image.asset.fluid && (
                      <>
                        <Img fluid={image.asset.fluid} alt={image.alt} />
                        <figcaption>{image.caption}</figcaption>
                      </>
                    )}
                  </figure>
                ))}
              </div>
            )}
          </ColumnsGrid>
        </Container>
      </Section>
    </section>
  );
};

const CenterText = styled.div`
  width: 100%;
  margin: 0 0 50px;

  @media (min-width: ${props => props.theme.screen.md}) {
    width: 50%;
    margin: 50px auto 100px;
    text-align: center;
  }
`;

export default Reviews;
