import React from 'react';
import styled, { keyframes } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { ReactComponent as Confetti } from '@static/icons/confetti.svg';
import { ReactComponent as Firework } from '@static/icons/firework.svg';
import { ReactComponent as Champagne } from '@static/icons/champagne.svg';
import { ReactComponent as Balloons } from '@static/icons/baloons.svg';
import { Section as TestimonialSection, Container } from '@components/global';
import { Button } from '../common/Button';
import BlockContent from '../common/BlockContent';

const TestimonialCta = ({ data }) => (
  <Section id="testimonial">
    <Confetti />
    <Firework />
    <Champagne />
    <Balloons />
    <Container>
      <Testimonial>
        <div className="quote">
          {data._rawTestimonial.quote && (
            <BlockContent data={data._rawTestimonial.quote} />
          )}
        </div>
        <footer>—{data.testimonial.person}</footer>
        <Button
          hasshadow="true"
          color="primary"
          href={data.testimonial.button.url}
        >
          {data.testimonial.button.text}
        </Button>
      </Testimonial>
    </Container>
  </Section>
);

const Testimonial = styled.blockquote`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div.quote {
    ${props => props.theme.font_size.larger};
    font-family: ${props => props.theme.font.primary};
    max-width: 500px;
    text-align: center;
    margin-bottom: 30px;
    position: relative;

    p {
      ${props => props.theme.font_size.large};
      font-family: ${props => props.theme.font.primary};

      @media (min-width: ${props => props.theme.screen.md}) {
        ${props => props.theme.font_size.larger};
      }
    }

    &:before {
      content: '“';
      font-size: 60px;
      color: ${props => props.theme.color.primary};
      position: absolute;
      top: -20px;
      left: -20px;

      @media (min-width: ${props => props.theme.screen.lg}) {
        font-size: 102px;
      }
    }
  }

  footer {
    color: ${props => props.theme.color.black};
    font-weight: ${props => props.theme.font_weight.bold};
    margin-bottom: 30px;
  }
`;

const cycleColor = props => keyframes`

  from {
    fill: ${props.theme.color.black_light};
  }

  33% {
    fill: ${props.theme.color.primary};
  }
  
  66% {
    fill: ${props.theme.color.secondary};
  }

  to {
    fill: ${props.theme.color.black_light};
  }
`;

const colorCycleDelay = 8; // seconds

const Section = styled(TestimonialSection)`
  position: relative;
  padding: 0;
  margin-top: 0;

  @media (min-width: ${props => props.theme.screen.md}) {
    padding: 100px 0;
    margin-top: 50px;
  }

  svg {
    position: absolute;
    opacity: 0.2;
    opacity: 1;
    display: none;

    @media (min-width: ${props => props.theme.screen.md}) {
      display: block;
    }

    path {
      fill: ${props => props.theme.color.black_light};
      animation: ${cycleColor} ${colorCycleDelay}s linear infinite;
    }

    &:nth-child(1) {
      left: 10%;
      bottom: 10%;
      transform: scale(0.7);

      @media (min-width: ${props => props.theme.screen.md}) {
        left: 15%;
        bottom: 20%;
        transform: scale(1);
      }

      path {
        animation-delay: ${colorCycleDelay * 0.25}s;
      }
    }
    &:nth-child(2) {
      left: 10%;
      top: 10%;
      transform: scale(0.7);

      @media (min-width: ${props => props.theme.screen.md}) {
        left: 35%;
        top: 00%;
        transform: scale(1);
      }

      path {
        animation-delay: ${colorCycleDelay * 0.5}s;
      }
    }
    &:nth-child(3) {
      right: 10%;
      bottom: 10%;
      transform: scale(0.7);

      @media (min-width: ${props => props.theme.screen.md}) {
        right: 35%;
        bottom: 20%;
        transform: scale(1);
      }

      path {
        animation-delay: ${colorCycleDelay / 0.75}s;
      }
    }
    &:nth-child(4) {
      right: 10%;
      top: 10%;
      transform: scale(0.7);

      @media (min-width: ${props => props.theme.screen.md}) {
        right: 15%;
        top: 35%;
        transform: scale(1);
      }

      path {
        animation-delay: ${colorCycleDelay}s;
      }
    }
  }
`;

export default TestimonialCta;
