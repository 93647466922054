import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { ReactComponent as Star } from '@static/icons/star.svg';

import { ReactComponent as Signature } from '@static/images/signature.svg';
import { Section, Container, Columns, ColumnsGrid } from '@components/global';
import { Button } from '../common/Button';
import BlockContent from '../common/BlockContent';
import SectionHeader from '../SectionHeader';

const About = ({ data, rawData }) => {
  const mapCenter = {
    lat: data.sectionHeading.location.lat,
    lng: data.sectionHeading.location.lng,
  };

  return (
    <section id="about">
      <SectionHeader center={mapCenter} zoom={15}>
        <h1 className={data.sectionHeading.subHeadingColor}>
          {data.sectionHeading.subHeading}
        </h1>
        <h2>{data.sectionHeading.heading}</h2>
      </SectionHeader>
      <Section>
        <Container>
          <ColumnsGrid className="content" alignItems="top">
            {rawData.text && (
              <div>
                <BlockContent data={rawData.text} />
                {data.quotes && data.quotes.length > 0 && (
                  <>
                    {data.quotes.map((data, i) => (
                      <KimQuote
                        key={data._key}
                        color={i % 2 === 0 ? 'primary' : 'secondary'}
                      >
                        {data.quote && <p>{data.quote}</p>}
                        {data.person && (
                          <footer>
                            {data.person && <strong>{data.person}</strong>}{' '}
                            {data.personPosition && (
                              <>| {data.personPosition}</>
                            )}
                          </footer>
                        )}

                        {/* <Signature /> */}
                        {data.signature &&
                          data.signature.asset &&
                          data.signature.asset.fluid && (
                            <figure>
                              <SignatureImg
                                fluid={data.signature.asset.fluid}
                                alt={data.signature.alt}
                              />
                            </figure>
                          )}
                      </KimQuote>
                    ))}
                  </>
                )}
              </div>
            )}
            <div>
              {data.image && data.image.length > 0 && (
                <>
                  {data.image.map(image => (
                    <figure key={image._key}>
                      {image && image.asset && image.asset.fluid && (
                        <>
                          <Img fluid={image.asset.fluid} alt={image.alt} />
                          <figcaption>{image.caption}</figcaption>
                        </>
                      )}
                    </figure>
                  ))}
                </>
              )}
            </div>
          </ColumnsGrid>
        </Container>
      </Section>
    </section>
  );
};

const KimQuote = styled.blockquote`
  margin-left: 30px;
  margin-top: 50px;

  p {
    font-family: ${props => props.theme.font.primary};
    ${props => props.theme.font_size.regular};
    /* font-size: 27px !important; */
    color: ${props => props.theme.color[props.color]};
    position: relative;
    padding-bottom: 15px;

    @media (min-width: ${props => props.theme.screen.sm}) {
      ${props => props.theme.font_size.large};
    }

    @media (min-width: ${props => props.theme.screen.lg}) {
      ${props => props.theme.font_size.larger};
    }

    &:before {
      content: '“';
      font-size: 102px;
      color: ${props => props.theme.color[props.color]};
      position: absolute;
      top: -25px;
      left: -30px;
    }
  }

  footer {
    ${props => props.theme.font_size.xsmall};
    color: ${props => props.theme.color.font.regular};

    strong {
      ${props => props.theme.font_size.regular};
      font-weight: ${props => props.theme.font_weight.bold};
      color: ${props => props.theme.color.black};
    }
  }

  svg {
    margin-top: 30px;
  }
`;

const SignatureImg = styled(Img)`
  width: 50%;
  max-width: 220px;
  margin-top: 30px;
`;

export default About;
