import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { ReactComponent as Star } from '@static/icons/star.svg';

import { ReactComponent as ZillowIcon } from '@static/icons/header/zillow.svg';
import { Section, Container, Columns, ColumnsGrid } from '@components/global';

import ExternalLink from '../common/ExternalLink';
import { Button } from '../common/Button';
import BlockContent from '../common/BlockContent';
import SectionHeader from '../SectionHeader';

const Reviews = ({ data, rawData }) => {
  const mapCenter = {
    lat: data.sectionHeading.location.lat,
    lng: data.sectionHeading.location.lng,
  };

  return (
    <section id="reviews">
      <SectionHeader center={mapCenter} zoom={15}>
        <h1 className={data.sectionHeading.subHeadingColor}>
          {data.sectionHeading.subHeading}
        </h1>
        <h2>{data.sectionHeading.heading}</h2>
      </SectionHeader>
      <Section>
        <Container style={{ marginBottom: '100px' }}>
          {/*  Sorry bout the inline 😇 */}
          <ColumnsGrid alignItems="center">
            <div>
              {rawData.text && <BlockContent data={rawData.text} />}
              <ZillowButtonWrap href={data.button.url}>
                <ZillowButton as="span">
                  {data.button.text} <ZillowIcon />
                </ZillowButton>
              </ZillowButtonWrap>
            </div>

            <figure>
              <Img fluid={data.image.asset.fluid} />
            </figure>
          </ColumnsGrid>
        </Container>
        {data.review && data.review.length > 0 && (
          <Container>
            <Columns>
              {data.review.map((review, i) => (
                <Review key={review._key + i}>
                  {rawData.review[i].quote && (
                    <BlockContent data={rawData.review[i].quote} />
                  )}
                  <footer>
                    {review.rating && <Stars count={review.rating} />}
                    {review.person && <span>– {review.person}</span>}
                  </footer>
                </Review>
              ))}
            </Columns>
          </Container>
        )}
      </Section>
    </section>
  );
};

const Stars = ({ count }) => (
  <StarsWrap>
    <StarStyled fill={count > 0 ? 'true' : 'false'} />
    <StarStyled fill={count > 1 ? 'true' : 'false'} />
    <StarStyled fill={count > 2 ? 'true' : 'false'} />
    <StarStyled fill={count > 3 ? 'true' : 'false'} />
    <StarStyled fill={count > 4 ? 'true' : 'false'} />
  </StarsWrap>
);

const Review = styled.blockquote`
  background-color: ${props => props.theme.color.white};
  margin: 0 0 50px 0;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  padding: 40px 20px 25px;
  position: relative;
  ${props => props.theme.font_size.small};

  @media (min-width: ${props => props.theme.screen.sm}) {
    padding: 80px 50px 50px;
    ${props => props.theme.font_size.regular};
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    content: '“';
    font-size: 60px;
    color: ${props => props.theme.color.black};
    position: absolute;
    top: 10px;
    left: 10px;

    @media (min-width: ${props => props.theme.screen.sm}) {
      font-size: 102px;
      top: 15px;
      left: 20px;
    }
  }

  footer {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    span {
      color: ${props => props.theme.color.black};
      ${props => props.theme.font_size.regular};
      font-weight: ${props => props.theme.font_weight.bold};
    }

    @media (min-width: ${props => props.theme.screen.sm}) {
      flex-direction: row;
    }
  }
`;

const StarsWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  justify-content: start;
  grid-gap: 10px;
`;

const StarStyled = styled(Star)`
  path {
    fill ${props => (props.fill === 'false' ? 'none' : '#E4C35D')};
  }
`;

const ZillowButtonWrap = styled(ExternalLink)`
  float: left;
  text-decoration: none;
`;

const ZillowButton = styled(Button)`
  background-color: #006aff;
  border-color: #006aff;
  margin-top: 30px;
  box-shadow: 0px 10px 20px rgba(0, 106, 255, 0.2);
  display: flex;
  align-items: center;

  svg {
    margin-left: 20px;
    path {
      fill: ${props => props.theme.color.white};
    }
  }
`;

export default Reviews;
