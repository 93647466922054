import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import slugify from 'slugify';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { ReactComponent as Beds } from '@static/icons/beds.svg';
import { ReactComponent as Bathrooms } from '@static/icons/bathrooms.svg';
import { ReactComponent as Area } from '@static/icons/area.svg';
import { Section, Container } from '@components/global';
import { Button } from '../common/Button';
import {
  addressFormat,
  address2Format,
  priceFormat,
  httpToHttps,
} from '../../helpers/propertyHelpers';

import SectionHeader from '../SectionHeader';

const Listings = ({ data }) => {
  const mapCenter = {
    lat: data.sanityHomepage.listings.sectionHeading.location.lat,
    lng: data.sanityHomepage.listings.sectionHeading.location.lng,
  };

  return (
    <section id="listings">
      <SectionHeader center={mapCenter} zoom={15}>
        <h1>
          Listings For{' '}
          <AnchorLink offset={() => 109} className="secondary" href="#for-sale">
            Sale
          </AnchorLink>{' '}
          &{' '}
          <AnchorLink offset={() => 109} href="#for-rent">
            Rent
          </AnchorLink>
        </h1>
        <h2>{data.sanityHomepage.listings.sectionHeading.heading}</h2>
      </SectionHeader>
      <Section>
        <Container>
          <ListingsWrap id="for-sale">
            <h3 className="heading secondary">For Sale</h3>
            <ListingsGrid>
              {data.allSanityProperties.edges.length > 0 ? (
                <>
                  {data.allSanityProperties.edges.map((edge) => {
                    const property = edge.node;
                    if (property.address) {
                      return (
                        <Listing
                          to={slugify(property.address)}
                          key={property.id}
                        >
                          <div>
                            <ListingImg>
                              {property.images &&
                              property.images.image &&
                              property.images.image.length > 0 ? (
                                <Img
                                  fluid={property.images.image[0].asset.fluid}
                                  alt={
                                    property.images.image[0].alt ||
                                    property.images.image[0].caption ||
                                    property.address
                                  }
                                />
                              ) : (
                                <img
                                  src="images/no-image.jpg"
                                  alt={property.address ? property.address : ''}
                                />
                              )}

                              <Tag color="secondary">
                                {property.underContract
                                  ? 'Under Contract'
                                  : 'For Sale'}
                              </Tag>
                            </ListingImg>
                            <ListingInfo>
                              <span className="address">
                                {addressFormat(
                                  property.address,
                                  property.nickname
                                )}
                              </span>
                              <span className="address2">
                                {address2Format(
                                  property.city,
                                  property.state,
                                  property.zip
                                )}
                              </span>
                              <span className="price secondary">
                                {priceFormat(property.price, false)}
                              </span>
                              {/* <hr /> */}
                            </ListingInfo>
                          </div>
                          {(property.bedrooms ||
                            property.bathrooms ||
                            property.sqft) && (
                            <AmentityList>
                              {property.bedrooms && (
                                <li>
                                  <span>{property.bedrooms}</span>
                                  <Beds />
                                </li>
                              )}
                              {property.bathrooms && (
                                <li>
                                  <span>{property.bathrooms}</span>
                                  <Bathrooms />
                                </li>
                              )}
                              {property.sqft && (
                                <li>
                                  <span>{property.sqft}</span>
                                  <Area />
                                </li>
                              )}
                            </AmentityList>
                          )}
                        </Listing>
                      );
                    }
                  })}
                </>
              ) : (
                <NoCurrentListings>
                  <span>No Current Listings</span>
                  <br />
                  <a href="mailto:sales@shahidproperties.com">
                    Contact us for any updates on upcoming properties
                  </a>
                </NoCurrentListings>
              )}
            </ListingsGrid>
          </ListingsWrap>

          <ListingsWrap id="for-rent">
            <h3 className="heading primary">For Rent</h3>
            <ListingsGrid>
              {data.rentec.properties.length > 0 ? (
                <>
                  {data.rentec.properties.map((property) => {
                    if (property.address) {
                      return (
                        <Listing
                          to={
                            slugify(property.address) === '264-Grove-Street'
                              ? '264-Grove-Street-rental'
                              : slugify(property.address)
                          }
                          key={property.property_id}
                        >
                          <div>
                            <ListingImg>
                              {property.images && property.images.length > 0 ? (
                                <LazyLoadImage
                                  src={httpToHttps(
                                    property.images[0].imagelink
                                  )}
                                  alt={property.address ? property.address : ''}
                                />
                              ) : (
                                <LazyLoadImage
                                  src="images/no-image.jpg"
                                  alt={property.address ? property.address : ''}
                                />
                              )}

                              <Tag color="primary">For Rent</Tag>
                            </ListingImg>
                            <ListingInfo>
                              <span className="address">
                                {addressFormat(
                                  property.address,
                                  property.nickname
                                )}
                              </span>
                              <span className="address2">
                                {address2Format(
                                  property.city,
                                  property.state,
                                  property.zip
                                )}
                              </span>
                              <span className="price primary">
                                {priceFormat(property.rent)}
                              </span>
                              {/* <hr /> */}
                            </ListingInfo>
                          </div>
                          {(property.bedrooms ||
                            property.bathrooms ||
                            property.sqft) && (
                            <AmentityList>
                              {property.bedrooms && (
                                <li>
                                  <span>{property.bedrooms}</span>
                                  <Beds />
                                </li>
                              )}
                              {property.bathrooms && (
                                <li>
                                  <span>{property.bathrooms}</span>
                                  <Bathrooms />
                                </li>
                              )}
                              {property.sqft && (
                                <li>
                                  <span>{property.sqft}</span>
                                  <Area />
                                </li>
                              )}
                            </AmentityList>
                          )}
                        </Listing>
                      );
                    }
                  })}
                </>
              ) : (
                <NoCurrentListings>
                  <span>No Current Rentals</span>
                  <br />
                  <a href="mailto:rentals@shahidproperties.com">
                    Contact us for any updates on upcoming rentals
                  </a>
                </NoCurrentListings>
              )}
            </ListingsGrid>
          </ListingsWrap>
        </Container>
      </Section>
    </section>
  );
};

const ListingsWrap = styled.div`
  border: 1px solid ${(props) => props.theme.color.black_light};
  border-radius: 2px;
  position: relative;
  padding: 0;
  margin-bottom: 50px;

  @media (min-width: ${(props) => props.theme.screen.sm}) {
    padding: 20px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .heading {
    background: ${(props) => props.theme.color.white};
    position: absolute;
    top: -25px;
    font-family: ${(props) => props.theme.font.secondary};
    font-weight: ${(props) => props.theme.font_weight.medium};
    color: ${(props) => props.theme.color.black};
    left: -1px;
    padding: 0 30px 20px 0;
    text-transform: uppercase;
    letter-spacing: ${(props) => props.theme.letter_spacing.loose};
    ${(props) => props.theme.font_size.regular};

    @media (min-width: ${(props) => props.theme.screen.sm}) {
      top: -11px;
    }

    &.primary {
      color: ${(props) => props.theme.color.primary};
    }

    &.secondary {
      color: ${(props) => props.theme.color.secondary};
    }
  }
`;

const ListingsGrid = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr;

  @media (min-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  @media (min-width: ${(props) => props.theme.screen.lg}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const ListingImg = styled.figure`
  width: 100%;
  height: 0;
  /* overflow: hidden; */
  display: block;
  padding-top: calc(232 / 333 * 100%);
  position: relative;
  margin-bottom: 20px;

  &:after {
    content: '';
    width: 90%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    ${(props) => props.theme.transition2};
  }

  img,
  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2 !important;
    border-radius: 3px;
  }
`;

const Listing = styled(Link)`
  padding: 20px;
  /* border: 1px solid ${(props) => props.theme.color.white}; */
  border-radius: 3px;
  ${(props) => props.theme.transition2};
  z-index: 2;
  border-bottom: 1px solid ${(props) => props.theme.color.black_light};
  text-decoration: none;
  display: grid;
  align-content: space-between;

  @media (min-width: ${(props) => props.theme.screen.sm}) {
    border-bottom: none;
  }

  &:hover {
    /* border-color: ${(props) => props.theme.color.black_light}; */
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.18),
      0px 2px 10px rgba(0, 0, 0, 0.07);
    ${ListingImg} {
      /* transform: translateY(-2px); */
      &:after {
        box-shadow: 0px 22px 25px rgba(0, 0, 0, 0.18);
        width: 85%;
      }
    }
    .address {
      text-decoration: underline;
    }
  }
`;

const Tag = styled(Button.withComponent('span'))`
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 2;
  font-size: 14px;
  padding: 0 15px;
  line-height: 31px;
  pointer-events: none;
`;

const ListingInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.color.font.regular};

  .address {
    ${(props) => props.theme.font_size.large};
    font-weight: ${(props) => props.theme.font_weight.medium};
    color: ${(props) => props.theme.color.black};
  }

  .price {
    ${(props) => props.theme.font_size.large};
    font-weight: ${(props) => props.theme.font_weight.medium};
    color: ${(props) => props.theme.color.black};
    margin-top: 10px;

    &.primary {
      color: ${(props) => props.theme.color.primary};
    }

    &.secondary {
      color: ${(props) => props.theme.color.secondary};
    }
  }

  hr {
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.color.black_light};
    border: 0;
    margin: 10px 0 15px;
  }
`;

const AmentityList = styled.ul`
  border-top: 1px solid ${(props) => props.theme.color.black_light};
  display: flex;
  align-items: center;
  margin: 10px 0 0 0;
  padding: 15px 0 0 0;
  align-self: end;

  li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    margin-left: 20px;
    color: ${(props) => props.theme.color.font.regular};

    &:first-child {
      margin-left: 0;
    }

    svg {
      margin-left: 10px;
    }
  }
`;

const NoCurrentListings = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: 1/-1;
  padding: 100px 0;
  text-align: center;

  span {
    font-family: ${(props) => props.theme.font.secondary};
    font-weight: ${(props) => props.theme.font_weight.medium};
    ${(props) => props.theme.font_size.large};
  }
`;

export default Listings;
